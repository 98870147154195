<template>
  <main>
    <breadcrumb title="İletişim" subTitle=""/>
    <div class="bd-services-2__area  pt-120">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="bd-services-2__item text-center mb-30 wow fadeInUp"
                 style="visibility: visible; animation-name: fadeInUp;">
              <a :href="'tel:'+contact.phone" class="bd-services-2__icon text-center">
                <i class="fa-thin fa-phone"></i>
              </a>
              <div class="bd-services-2__content">
                <h5 class="bd-services-2__title"><a :href="'tel:'+contact.phone">{{ contact.phone }}</a></h5>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="bd-services-2__item text-center mb-30 wow fadeInUp"
                 style="visibility: visible; animation-name: fadeInUp;">
              <a :href="'mailto:'+contact.mail" class="bd-services-2__icon text-center">
                <i class="fa-thin fa-envelope"></i>
              </a>
              <div class="bd-services-2__content">
                <h3 class="bd-services-2__title"><a :href="'mailto:'+contact.mail">{{ contact.mail }}</a></h3>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="bd-services-2__item text-center mb-30 wow fadeInUp"
                 style="visibility: visible; animation-name: fadeInUp;">
              <a target="_blank"
                 :href="contact.addrurl"
                 class="bd-services-2__icon text-center">
                <i class="fa-thin fa-location-arrow"></i>
              </a>
              <div class="bd-services-2__content">
                <a target="_blank"
                   :href="contact.addrurl">B
                {{contact.addr}}</a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bd-contact__section pb-90">
      <div class="container">
        <div class="row mb-3 pt-4 mt-3theme-bg" :style="{ backgroundImage: 'url(' + require('@/assets/img/parallax/contact.png') + ')',backgroundPosition:'center right' }"  >
          <div class="col-lg-8">
            <div class="postbox__comment-form">
              <h3 class="postbox__comment-form-title  wow fadeInUp">İletişim Formu</h3>
              <form action="#">
                <div class="row  wow fadeInUp">
                  <div class="col-xxl-6 col-xl-6 col-lg-6">
                    <div class="postbox__comment-input">
                      <input type="text" v-model="name" placeholder="Adınız Soyadınız" required>
                    </div>
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6">
                    <div class="postbox__comment-input">
                      <input type="email" v-model="mail" placeholder="E-Posta Adresiniz" required>
                    </div>
                  </div>
                  <div class="col-xxl-12">
                    <div class="postbox__comment-input">
                      <input type="text" v-model="sub" placeholder="Konu" required>
                    </div>
                  </div>
                  <div class="col-xxl-12">
                    <div class="postbox__comment-input">
                      <textarea v-model="des" placeholder="Mesajınız ..." required></textarea>
                    </div>
                  </div>
                  <div class="col-xxl-12">
                    <div class="postbox__comment-btn">
                      <button type="button" @click="post" class="bd-btn">Gönder</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <iframe class="postbox__comment-form p-0  wow fadeInUp"
                    :src="contact.mapurl"
                    width="100%" height="450" style="border:0;margin: 0" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="popup" :class="{'acc': popupText}" @click="popup">
      <div class="content">
        <i>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></i>
        <p>{{ popupText }}</p>
      </div>
    </div>
    <app-footer/>
  </main>
</template>
<script>
import breadcrumb from "@/components/Common/Breadcrumb";
import appFooter from "@/components/Common/Footer";
import axiosCustom from "@/axiosCustom";
import axios from 'axios'

export default {
  name: 'App',
  components: {breadcrumb,appFooter},
  data() {
    return {
      contact:[],
      form:[],
      name: null,
      mail: null,
      sub: null,
      des: null,
      popupText:null
    }
  },
  created() {
    document.title = 'İletişim'
    axiosCustom
        .get('contact')
        .then(response => {
          this.contact = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
  },
  methods:{
    post: function () {
     if ( this.name == null | this.mail == null | this.sub == null | this.des == null){
       this.popupText = 'Formu Eksiksiz Doldurun.'
     }else{
       const data = new FormData();
       data.append('name', this.name)
       data.append('mail', this.mail)
       data.append('sub', this.sub)
       data.append('des', this.des)

       axios.post('https://yonet.gifadwork.com/apipost/konak/contactmail', data)
           .then(response =>{
             this.popupText = 'Başvurunuz Alınmıştır'
             this.name = null
             this.mail = null
             this.sub = null
             this.des = null
             window.scrollTo(0, 0);
           })
           .catch(error => console.log(error))
     }
    },
    popup: function (){
      if(this.popupText != null){
        this.popupText = null
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter(to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>
<style>
.popup{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fffd;
  width: 100vw;
  height: 100vh;
}
.popup.acc{
  display: block;
}
.popup .content{
  display: block;
  position: relative;
  width: 300px;
  left: calc(50% - 150px);
  background-color: #fff;
  top: 50%;
  padding: 20px;
  padding-top: 35px;
  box-shadow: rgba(93, 23, 23, 0.25) 0px 25px 50px -12px;
}
.popup .content i{
  position: absolute;
  top: 10px;
  right: 10px;
}

</style>
